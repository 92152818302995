
export class StandartErrors {

    public static invalidDataType(): void {
        throw Error('Error. Invalid data type.');
    }

    public static needSetElement(): void {
        throw Error('Error. Need set element or data.');
    }

    public static elementIsNotFound(): void {
        throw Error('Error. Element is not found');
    }
}
